import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IFonctionnalite } from 'src/app/interface/fonctionnalite.interface';
import { ApplicationService } from 'src/app/services/application/application.service';
import { FonctionnaliteService } from 'src/app/services/fonctionnalite/fonctionnalite.service';
import { ProfilService } from 'src/app/services/profil/profil.service';

@Component({
  selector: 'app-modal-profil',
  templateUrl: './modal-profil.component.html',
  styleUrls: ['./modal-profil.component.scss']
})
export class ModalProfilComponent implements OnInit {
  profil = {
    "code": "",
    "libelle": "",
    "applicationId": "",
    "datasFonctionnalite": [] as Array<any>
  }
  datasFonctionnalite: Array<any> = []
  fonctionnalites: Array<IFonctionnalite> = []
  applications: Array<any> = []
  @Input() profilId: number;
  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private fonctionnaliteService: FonctionnaliteService,
    private applicationService: ApplicationService,
    private profilService: ProfilService,
    private ngxUiLoaderService: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    if (this.profilId) {
      this.getProfilData()
    }
    this.getFonctionnalites()
    this.getApplications()
  }

  getFonctionnalites() {
    this.fonctionnaliteService.get().subscribe({
      next: (response) => {
        if (!response.hasError) {
          this.fonctionnalites = response.items
        }
      },
      error: (error) => {
        this.toastr.error("Désolé, nous ne parvenons pas à récuperer les fonctionnalités")
        console.error({ error })
        throw new Error(`Une erreur est survenue ${JSON.stringify(error)}`);

      }
    })
  }
  getProfilData() {
    this.profilService.findOne(this.profilId).subscribe({
      next: (response) => {
        if (!response.hasError) {
          this.profil.code = response.items[0].code
          this.profil.libelle = response.items[0].libelle
          this.profil.applicationId = response.items[0].applicationId
          this.datasFonctionnalite = response.items[0].datasFonctionnalite.map((fonctionnalite: any) => {
            return fonctionnalite.id
          })
        }
      }
    })
  }
  getApplications() {
    this.applicationService.get().subscribe({
      next: (response) => {
        if (!response.hasError) {
          this.applications = response.items
        }
      },
      error: (error) => {
        this.toastr.error("Désolé, nous ne parvenons pas à récuperer les applications")
        console.error({ error })
        throw new Error(`Une erreur est survenue ${JSON.stringify(error)}`);

      }
    })
  }

  create() {
    this.profilService.create(this.profil).subscribe({
      next: (response) => {
        if (!response.hasError) {
          this.toastr.success("Informations enregistrées avec succès.", "Succès")
          this.activeModal.close("success")
        } else {
          this.toastr.error(`Impossible de créer le profile. Erreur: ${response.status && response.status.hasOwnProperty('message') ? response.status.message : "Inconnue"}`, "Erreur")
        }
      },
      error: (error) => {
        this.toastr.error("Nous ne parvenons pas à créer le profile. Veuillez reessayer plus tard.", "Erreur")
        throw new Error(`Erreur de création de profile. Erreur: ${JSON.stringify(error)}`);
      }
    })
  }

  update() {
    Object.assign(this.profil, { id: this.profilId })
    this.profilService.update(this.profil).subscribe({
      next: (response) => {
        if (!response.hasError) {
          this.toastr.success("Informations enregistrées avec succès.", "Succès")
          this.activeModal.close("success")
        } else {
          this.toastr.error(`Impossible de modifier le profile. Erreur: ${response.status && response.status.hasOwnProperty('message') ? response.status.message : "Inconnue"}`, "Erreur")
        }
      },
      error: (error) => {
        this.toastr.error("Nous ne parvenons pas à créer le profile. Veuillez reessayer plus tard.", "Erreur")
        throw new Error(`Erreur de création de profile. Erreur: ${JSON.stringify(error)}`);
      }
    })
  }

  onSubmit() {
    this.datasFonctionnalite.map(item => {
      this.profil.datasFonctionnalite.push({ id: item.toString() })
    })

    if (this.profilId) {
      this.update()
    } else {
      this.create()
    }
  }

}
