<div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <div class="py-4">
        <div class="accordion-enregistrement bg-white">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="accordion-enregistrement-item">
                        <div class="p-3">
                            <form class="form__enregistrement__base" (ngSubmit)="onSubmit()" #updateSeuilForm="ngForm">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <label for="code" class="form-label">Code*</label>
                                            <input type="text" class="form-control" required [(ngModel)]="seuil.code" name="code" id="code" readonly />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <label for="libelle" class="form-label">Libellé*</label>
                                            <input type="text" class="form-control" required [(ngModel)]="seuil.libelle" name="libelle" id="libelle" readonly />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <label for="valeur" class="form-label">Valeur*</label>
                                            <input id="valeur" type="text" class="form-control" required [(ngModel)]="seuil.valeur" name="valeur" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-3 text-center">
                                            <button type="submit" class="btn btn-primary submit-btn">Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
