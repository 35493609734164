<div class="filter-container">
  <button type="button" class="filter-button" filterToggle>Filtre <fa-icon [icon]="faSliders"></fa-icon></button>
  <div class="filter-menu">
    <div class="filter-head">
      <h5>Filtre</h5>
    </div>
    <div class="filter-body">
      <div class="top-action">
        <label class="label-toggle" for="enabled-thirty-days-filter">Actif 30 jours</label>
        <label class="toggler-wrapper">
          <input type="checkbox" id="enabled-thirty-days-filter" [(ngModel)]="thirtyDaysFilter"
            (change)="onChangeThirtyDaysFilter()">
          <div class="toggler-slider">
            <div class="toggler-knob"></div>
          </div>
        </label>
      </div>
      <div class="filters">
        <div *ngIf="showFilterRegionsAndTerritories()">
          <app-regions-selector-filter></app-regions-selector-filter>
          <app-multi-selector-filter type="territories" title="Territoires"></app-multi-selector-filter>
        </div>
        <div>
          <app-multi-selector-filter type="distributors" title="Distributeurs"></app-multi-selector-filter>
          <app-multi-selector-filter type="pdvs" title="PDVs"></app-multi-selector-filter>
        </div>
      </div>
    </div>
    <div class="filter-foot d-flex justify-content-end">
      <button type="button" class="filter-confirm-button btn btn-primary btn-sm" (click)="onSubmitFilter()" filterHide>Lancer le filtre</button>
      <button type="button" class="btn btn-custom-cancel btn-sm" filterHide>Annuler</button>
    </div>
  </div>
</div>
