import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberHumanFormat'
})
export class NumberHumanFormatPipe implements PipeTransform {

  transform(value: number, thousandSeparator = ' ', decimalLimit = 2): unknown {
    return value.toFixed(decimalLimit).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, thousandSeparator);
  }
}
