<div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <div class="py-4">
        <div class="accordion-enregistrement bg-white">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="accordion-enregistrement-item">
                        <div class="p-3">
                            <form class="form__enregistrement__base" [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <label for="" class="form-label">Code*</label>
                                            <input type="text" class="form-control" formControlName="code" readonly required />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <label for="" class="form-label">Libellé*</label>
                                            <input type="text" class="form-control" formControlName="libelle" readonly required />
                                        </div>
                                    </div>
                                </div>
                                <fieldset class="mb-4">
                                <legend>Personnes à notifier</legend>
                                <ng-container formArrayName="datasPersonne" *ngFor="let person of datasPersonne.controls; index as i">
                                    <ng-container [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-md-5 col-12">
                                                <div class="mb-3">
                                                    <label for="" class="form-label">Contact*</label>
                                                    <input type="text" class="form-control" formControlName="contact" required />
                                                </div>
                                            </div>
                                            <div class="col-md-5 col-12">
                                                <div class="mb-3">
                                                    <label for="" class="form-label">Adresse email*</label>
                                                    <input type="email" class="form-control" formControlName="email" required />
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-12 d-flex">
                                                <div class="align-self-center">
                                                    <button class="btn-remove btn-danger" type="button" (click)="removePersonField(i)">x</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <div class="row">
                                    <div class="col-md-12">
                                        <button class="btn btn-info" type="button" (click)="addPersonField()">Ajouter une personne</button>
                                    </div>
                                </div>
                                </fieldset>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-3 text-center">
                                            <button type="submit" class="btn btn-primary submit-btn d-block w-100">Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    </div>
</div>
    