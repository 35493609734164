<div class="fieldset">
  <div class="head">
    <h5>Régions</h5>
    <div class="form-check checkbox-rect">
      <input class="form-check-input" type="checkbox" [(ngModel)]="allSelected" (change)="toggleAllSelected()" id="filter-regions-select-all">
      <label class="form-check-label" for="filter-regions-select-all">
        Tous
      </label>
    </div>
  </div>
  <div class="fieldset-body">
      <div class="row">
        <div class="form-check checkbox-rect col-md-6" *ngFor="let region of regionsFilter; let i= index">
          <input class="form-check-input" type="checkbox" [(ngModel)]="region.value" (change)="valueChanged(region.value)" [name]="region.id" [id]="'filter-regions-select-'+region.id">
          <label class="form-check-label" [for]="'filter-regions-select-'+region.id">
            {{ region.label }}
          </label>
        </div>
      </div>
  </div>
</div>
