import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  message: string = "Voulez-vous vraiment supprimer cette donnée ?"
  confirmButtonText = "Oui"
  cancelButtonText = "Annuler"
  constructor() { }

  ngOnInit(): void {
  }

  onConfirmClick(): void {
    //this.dialogRef.close(true);
  }

}
