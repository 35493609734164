import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() total: number;
  @Input() dataPerPage: number;
  @Output() firstPageEvent = new EventEmitter();
  @Output() lastPageEvent = new EventEmitter();
  @Output() previousPageEvent = new EventEmitter();
  @Output() nextPageEvent = new EventEmitter();
  @Output() pageEvent = new EventEmitter<number>();
  totalPage: number;
  currentPage: number
  constructor() { }

  ngOnInit(): void {
    this.totalPage = Math.ceil(this.total / this.dataPerPage)
    this.currentPage = 0;
  }
  goToFirstPage() {
    this.currentPage = 0;
    this.firstPageEvent.emit()
  }
  goToLastPage() {
    this.currentPage = this.totalPage - 1;
    this.lastPageEvent.emit()
  }
  goToPreviousPage() {
    if (this.currentPage !== 0) {
      this.currentPage = this.currentPage - 1
      this.previousPageEvent.emit(this.currentPage)
    }
  }
  goToNextPage() {
    if (this.totalPage !== this.currentPage) {
      this.currentPage = this.currentPage + 1
      this.nextPageEvent.emit(this.currentPage)
    }
  }
  goToPage(pageNumber: number) {
    if (pageNumber !== this.currentPage) {
      this.currentPage = pageNumber
      this.pageEvent.emit(pageNumber)
    }
  }

  counter(i: number) {
    return new Array(i);
  }

}
