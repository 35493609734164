import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from './filters.component';
import { MultiSelectorFilterComponent } from './multi-selector-filter/multi-selector-filter.component';
import { RegionsSelectorFilterComponent } from './regions-selector-filter/regions-selector-filter.component';
import { FiltersService } from './filters.service';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterToggleDirective } from './directives/toggle.directive';
import { FilterHideDirective } from './directives/hide.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [FiltersComponent, MultiSelectorFilterComponent, RegionsSelectorFilterComponent, FilterToggleDirective, FilterHideDirective],
  imports: [
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    FontAwesomeModule
  ],
  providers: [
    FiltersService,
  ],
  exports: [FiltersComponent]
})
export class FiltersModule { }