import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IAlerte, IDatasPersonne } from 'src/app/interface/alerte.interface';
import { AlerteService } from 'src/app/services/alerte/alerte.service';

@Component({
  selector: 'app-modal-alerte',
  templateUrl: './modal-alerte.component.html',
  styleUrls: ['./modal-alerte.component.scss']
})
export class ModalAlerteComponent implements OnInit {
  //alerte: IAlerte;
  @Input() alerte: IAlerte;
  modalTitle: string = 'Ajouter une alerte';
  form: UntypedFormGroup;
  constructor(
    private alerteService: AlerteService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({})
    if (this.alerte) {
      this.form.addControl('id', new UntypedFormControl(this.alerte.id));
      this.modalTitle = 'Modifier cette alerte'
    } else {
      this.alerte = {
        libelle: '',
        code: '',
        datasPersonne: [{ contact: '', email: '' }]
      }
    }

    this.form.addControl('code', new UntypedFormControl(this.alerte.code))
    this.form.addControl('libelle', new UntypedFormControl(this.alerte.libelle))
    this.form.addControl('datasPersonne', this.buildPersons(this.alerte.datasPersonne))

    // this.form = this.formBuilder.group({
    //   code: this.alerte.code,
    //   libelle: this.alerte.libelle,
    //   datasPersonne: this.buildPersons(this.alerte.datasPersonne)
    // });
  }

  get datasPersonne(): UntypedFormArray {
    return this.form.get('datasPersonne') as UntypedFormArray;
  }

  buildPersons(persons: { contact: string; email: string; }[] = []) {
    return this.formBuilder.array(persons.map(person => this.formBuilder.group(person)));
  }

  addPersonField() {
    this.datasPersonne.push(this.formBuilder.group({ contact: null, email: null }))
    this.changeDetectorRef.detectChanges()
  }

  removePersonField(index: number): void {
    if (this.datasPersonne.length > 1) this.datasPersonne.removeAt(index);
    else this.datasPersonne.patchValue([{ contact: null, email: null }]);

    this.changeDetectorRef.detectChanges()
  }
  update() {
    this.alerteService.update(this.form.value).subscribe({
      next: (response) => {
        if (!response.hasError) {
          this.toastr.success("Informations enregistrées avec succès.", "Succès")
          this.activeModal.close("success")
        } else {
          this.toastr.error(`Impossible de modifier l'alerte. Erreur: ${response.status && response.status.hasOwnProperty('message') ? response.status.message : "Inconnue"}`, "Erreur")
        }
      },
      error: (error) => {
        this.toastr.error("Nous ne parvenons pas à modifier l'alerte. Veuillez reessayer plus tard.", "Erreur")
        throw new Error(`Erreur de modification de l'alerte. Erreur: ${JSON.stringify(error)}`);
      }
    })
  }

  create() {
    this.alerteService.create(this.form.value).subscribe({
      next: (response) => {
        if (!response.hasError) {
          this.toastr.success("Informations enregistrées avec succès.", "Succès")
          this.activeModal.close("success")
        } else {
          this.toastr.error(`Impossible d'ajouter l'alerte. Erreur: ${response.status && response.status.hasOwnProperty('message') ? response.status.message : "Inconnue"}`, "Erreur")
        }
      },
      error: (error) => {
        this.toastr.error("Nous ne parvenons pas à ajouter l'alerte. Veuillez reessayer plus tard.", "Erreur")
        throw new Error(`Erreur de création de l'alerte. Erreur: ${JSON.stringify(error)}`);
      }
    })
  }

  onSubmit() {
    this.form.value.datasPersonne = this.form.value.datasPersonne.filter((person: IDatasPersonne) => person.contact !== null && person.email !== null)
    if (this.alerte.id && this.alerte.id !== 0) {
      this.update()
    } else {
      this.create()
    }
  }

}
