import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DistributeurService } from 'src/app/services/distributeur/distributeur.service';
import { TemplateService } from 'src/app/services/template/template.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
  selector: 'app-modal-import-distributeur',
  templateUrl: './modal-import-distributeur.component.html',
  styleUrls: ['./modal-import-distributeur.component.scss']
})
export class ModalImportDistributeurComponent implements OnInit {
  currentDate = new Date();
  fileName = '';
  file: File;
  constructor(
    public activeModal: NgbActiveModal,
    private distributeurService: DistributeurService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private templateService: TemplateService,
    private utilitiesService: UtilitiesService
  ) { }

  ngOnInit(): void {
  }

  downloadFile(url: string) {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', `regions${this.currentDate.getDay()}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadTemplateFile() {
    this.templateService.getTemplateFile("DISTRIBUTEURS").subscribe({
      next: (response) => {
        this.utilitiesService.downloadFile(response.body)
      }
    })
  }

  getUploadedFilePath() {
    const getGeoJSONFilePathRequest = this.distributeurService.getUploadedFilePath().subscribe({
      next: (response: any) => {
        if (!response.hasError && response.hasOwnProperty('filePath')) {
          this.downloadFile(response.filePath)
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer le fichier', 'Information');
          //this.authenticationFailed = true
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      }
    })
  }

  onFilechange(event: any) {
    this.file = event.target.files[0]
    this.fileName = event.target.files[0].name
  }

  uploadFile() {
    if (this.file) {
      const uploadZoneRequest = this.distributeurService.upload(this.file).subscribe({
        next: (response: any) => {
          if (response && response.hasOwnProperty('hasError') && !response.hasError) {
            this.toastr.success('Le fichier a été chargé avec succès', 'Information');
            return;
          } else {
            this.toastr.error('Oops! Nous ne parvenons pas à charger ce fichier', 'Information');
            //this.authenticationFailed = true
          }
        },
        error: (error: any) => {
          this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
          this.activeModal.dismiss()
          throw new Error(error)
        },
        complete: () => {
          //this.loginInProgress = false
          this.activeModal.dismiss()
        }
      })

      setTimeout(() => {
        uploadZoneRequest.unsubscribe();
        //this.loginInProgress = false
      }, 60000);
    } else {
      this.toastr.error('Veuillez selectionner un fichier', 'Information');
    }
  }

}
