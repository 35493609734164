import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TerritoireCommercialService } from 'src/app/services/territoire-commercial/territoire-commercial.service';
import { TemplateService } from 'src/app/services/template/template.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
@Component({
  selector: 'app-modal-import-territoire',
  templateUrl: './modal-import-territoire.component.html',
  styleUrls: ['./modal-import-territoire.component.scss']
})
export class ModalImportTerritoireComponent implements OnInit {
  @Input() fileType: string
  currentDate = new Date();
  fileName = '';
  file: File;
  constructor(
    public activeModal: NgbActiveModal,
    private territoireService: TerritoireCommercialService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private ngxUiLoaderService: NgxUiLoaderService,
    private elementRef: ElementRef,
    private templateService: TemplateService,
    private utilitiesService: UtilitiesService
  ) { }

  ngOnInit(): void {
  }

  downloadFile() {
    this.templateService.getTemplateFile("TERRITOIRE").subscribe({
      next: (response) => {
        this.utilitiesService.downloadFile(response.body)
      }
    })
  }

  onFilechange(event: any) {
    this.file = event.target.files[0]
    this.fileName = event.target.files[0].name
  }

  uploadFile() {
    if (this.file && this.fileType) {
      this.ngxUiLoaderService.startLoader('modal-territoire')
      const uploadTerritoireRequest = this.territoireService.upload(this.file, this.fileType).subscribe({
        next: (response: any) => {
          if (response && response.hasOwnProperty('hasError') && !response.hasError) {
            this.toastr.success('Le fichier a été chargé avec succès', 'Information');
            return;
          } else {
            this.toastr.error('Oops! Nous ne parvenons pas à charger ce fichier', 'Information');
            //this.authenticationFailed = true
          }
        },
        error: (error: any) => {
          this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
          this.ngxUiLoaderService.stopLoader('modal-territoire')
          throw new Error(error)
        },
        complete: () => {
          //this.loginInProgress = false
          this.activeModal.close()
          this.ngxUiLoaderService.stopLoader('modal-territoire')
        }
      })

      // setTimeout(() => {
      //   uploadTerritoireRequest.unsubscribe();
      //   //this.loginInProgress = false
      // }, 60000);
    } else {
      this.toastr.error('Veuillez selectionner un fichier', 'Information');
    }
  }

}
