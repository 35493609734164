<div class="modal-header">
<h4 class="modal-title">Ajouter un profil</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close('cross click')"></button>
</div>
<div class="modal-body">
    <div class="py-4">
        <div class="accordion-enregistrement bg-white">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="accordion-enregistrement-item">
                        <div class="p-3">
                            <form class="form__enregistrement__base" (ngSubmit)="onSubmit()" #createProfileForm="ngForm">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <label for="" class="form-label">Code*</label>
                                            <input type="text" class="form-control" required [(ngModel)]="profil.code" name="code" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <label for="" class="form-label">Libellé*</label>
                                            <input type="text" class="form-control" required [(ngModel)]="profil.libelle" name="libelle" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <label for="" class="form-label">Application*</label>
                                            <select name="application" class="form-control" [(ngModel)]="profil.applicationId">
                                                <option [value]="application.id" *ngFor="let application of applications">{{ application.libelle }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <div class="mb-3">
                                            <label for="" class="form-label">Selectionner les fonctionnalités*</label>
                                            
                                            <ng-select
                                                [items]="fonctionnalites"
                                                [multiple]="true"
                                                bindLabel="libelle"
                                                [selectableGroup]="false"
                                                [closeOnSelect]="false"
                                                bindValue="id"
                                                name="fonctionnalites"
                                                [(ngModel)]="datasFonctionnalite">
                                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                    <input id="item-{{index}}" class="form__check__input" type="checkbox" [name]="'fonctionnalite-'+index" [ngModel]="item$.selected"/> {{item.libelle}}
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-3 text-center">
                                            <button type="submit" class="btn btn-primary submit-btn">Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    </div>
</div>
