import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[filterHide]'
})
export class FilterHideDirective {

  constructor(private el: ElementRef) {}

  @HostListener('click')
  onClick() {
    this.el.nativeElement.closest('.filter-menu').style.display = 'none';
  }
}
