<div class="app-component last-update-component">
    <ul class="list-unstyled">
        <li>
          <span class="label text-primary">Stock PDV: </span><span class="text-value">{{ lastUpdateDate + '-' + lastUpdateHour }}</span>
        </li>
        <li>
          <span class="label text-primary">Stock Distributeur: </span><span class="text-value">{{ lastUpdateDate + '-' + lastUpdateHour }}</span>
        </li>
        <li>
          <span class="label text-primary">Commission: </span><span class="text-value">-</span>
        </li>
        <li>
          <span class="label text-primary">Transaction: </span><span class="text-value">-</span>
        </li>
    </ul>
</div>
