import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[filterToggle]'
})
export class FilterToggleDirective implements OnInit {
  // A flag that keeps track of whether the content is currently shown
  private isShown = false;

  constructor(private el: ElementRef) {}
  ngOnInit(): void {
    this.el.nativeElement.nextElementSibling.style.display = this.showNextElementSibling();
  }

  // Show the content when the host element is clicked
  @HostListener('click')
  onClick() {
    this.isShown = this.el.nativeElement.nextElementSibling.style.display=='none' ? true : false
    this.el.nativeElement.nextElementSibling.style.display = this.showNextElementSibling();
  }

  // Hide the content when clicked outside of the host element
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.el.nativeElement.nextElementSibling.contains(event.target) && event.target !=this.el.nativeElement) {
      this.isShown = false;
      this.el.nativeElement.nextElementSibling.style.display = 'none';
    }
  }

  showNextElementSibling(){
    return  this.isShown ? 'block' : 'none';
  }
}
