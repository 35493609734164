import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardFilterDataService } from 'src/app/services/dashboard-filter-data/dashboard-filter-data.service';
import { DistributeurService } from 'src/app/services/distributeur/distributeur.service';
import { LastUpdateDataService } from 'src/app/services/last-update-data/last-update-data.service';
import { PdvService } from 'src/app/services/pdv/pdv.service';

@Component({
  selector: 'app-last-update-data',
  templateUrl: './last-update-data.component.html',
  styleUrls: ['./last-update-data.component.scss']
})
export class LastUpdateDataComponent implements OnInit {
  lastUpdateDate: string = ''
  lastUpdateHour: string = ''
  constructor(
    private distributeurService: DistributeurService,
    private pdvService: PdvService,
    private toastr: ToastrService,
    private lastUpdateDataService: LastUpdateDataService,
    private dashboardService: DashboardFilterDataService
  ) { }

  ngOnInit(): void {
    this.getLastDistributeurStockUpdate()
  }

  private getLastDistributeurStockUpdate() {
    const getKPIRequest = this.pdvService.getLastStockUpdate().subscribe({
      next: (response: any) => {
        if (response && response.hasOwnProperty('hasError') && !response.hasError) {
          this.lastUpdateDate = `${response.item.date}`
          this.lastUpdateHour = response.item.heure
          //Evenement pour date de dernière mise à jour
          this.lastUpdateDataService.setLastUpdateData({ startDate: response.item.date, endDate: response.item.date, startHour: response.item.heure, endHour: response.item.heure })
          //Evenement pour récupérer les données selon la date de dernière mise à jour
          this.dashboardService.filterData({ startDate: response.item.date, endDate: response.item.date, startHour: response.item.heure, endHour: response.item.heure, isLastDate: true })
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer les dernières mises à jour', 'Information');
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      },
      complete: () => {
        //this.loginInProgress = false
      }
    })
  }

}
