import { Subscription } from 'rxjs';
import { IZone } from './../../../../../../interface/zone.interface';
import { FiltersService } from '../filters.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { errorHandling } from '../operators/errorHandling.operator';

@Component({
  selector: 'app-regions-selector-filter',
  templateUrl: './regions-selector-filter.component.html',
  styleUrls: ['./regions-selector-filter.component.scss', '../filters.scss']
})
export class RegionsSelectorFilterComponent implements OnInit, OnDestroy {
  regionsFilter: {
    label: string;
    id: string;
    value: boolean;
  }[] = [];
  allSelected = false
  getRegionsSubscription: Subscription

  constructor(private filtersService: FiltersService, private toastr: ToastrService) { }


  ngOnInit(): void {
    this.getRegionsSubscription = this.filtersService.getRegions().pipe(
      errorHandling((errorMessage)=> this.toastr.error(errorMessage, 'Error'), 'Oops! Une erreur inattendue a été rencontrée lors du chargement des Régions.', {items: []})
    ).subscribe({
      next: ({ items }: { items: IZone[] }) => {
        items.forEach(region => {
          this.regionsFilter.push({
            label: region.libelle,
            id: region.id!,
            value: false
          })
        })
      },
      error: () =>{
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
      }
    })
  }

  ngOnDestroy(): void {
    this.getRegionsSubscription.unsubscribe()
  }

  valueChanged(value: boolean) {
    if (value === false && this.allSelected) {
      this.allSelected = false
    }

    this.updateFilterService()
  }

  toggleAllSelected() {
    this.regionsFilter = this.regionsFilter.map(region => {
      region.value = this.allSelected
      return region
    })

    this.updateFilterService()
  }

  updateFilterService() {
    const selectedRegions = this.regionsFilter.filter(region => region.value)
    this.filtersService.updateSelectedRegions(selectedRegions)
  }
}
