import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProfile, IProfileBodyDatas, IProfileResponseBody } from 'src/app/interface/profile.interface';
import { IUser } from 'src/app/interface/user.interface';
import { RestClientService } from '../rest-client.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ProfilService {
  private readonly defaultBody = {
    "id": "",
    "code": "",
    "libelle": "",
    "applicationId": "",
    "typeProfilId": "",
    "createdAt": "",
    "updatedAt": "",
    "deletedAt": "",
    "createdBy": "",
    "updatedBy": "",
    "deletedBy": "",
    "isDeleted": ""
  }
  private readonly basePath = 'profil'
  constructor(
    private restClient: RestClientService,
    private user: UserService
  ) { }

  get(): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": { ...this.defaultBody }
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }
  findByApplication(applicationId: number): Observable<any> {
    const datas = { ...this.defaultBody }
    datas.applicationId = applicationId.toString()
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": datas
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }
  findOne(id: number): Observable<IProfileResponseBody> {
    const data = { ...this.defaultBody }
    data.id = id.toString()
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": data
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }
  create(datas: IProfileBodyDatas): Observable<IProfileResponseBody> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [datas]
    }
    return this.restClient.post(`${this.basePath}/create`, bodyDatas)
  }
  update(datas: IProfileBodyDatas): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [datas]
    }
    return this.restClient.post(`${this.basePath}/update`, bodyDatas)
  }
  delete(id: number): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [{ id }]
    }
    return this.restClient.post(`${this.basePath}/delete`, bodyDatas)
  }
}
