import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { RestClientService } from '../rest-client.service';
import { IAlerte } from 'src/app/interface/alerte.interface';

@Injectable({
  providedIn: 'root'
})
export class AlerteService {
  readonly defaultBody = {
    id: 0
  }
  readonly basePath = 'typeAlerte'
  constructor(
    private user: UserService,
    private restClient: RestClientService
  ) { }

  get(
    index: number = 0,
    size: number = 10,
    searchTerm: string = ''
  ): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": { ...this.defaultBody }
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }
  findOne(id: number): Observable<any> {
    const data = { ...this.defaultBody }
    data.id = id
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": { ...this.defaultBody }
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }

  update(datas: IAlerte): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [datas]
    }
    return this.restClient.post(`${this.basePath}/update`, bodyDatas)
  }
  create(datas: IAlerte): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [datas]
    }
    return this.restClient.post(`${this.basePath}/createPersonne`, bodyDatas)
  }
  delete(id: number): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [{ id }]
    }
    return this.restClient.post(`${this.basePath}/delete`, bodyDatas)
  }
}
