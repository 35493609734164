import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { RestClientService } from '../rest-client.service';
import {ISeuil, ISeuilResponseBody, SeuilPayload} from 'src/app/interface/seuil.interface';

@Injectable({
  providedIn: 'root'
})
export class SeuilService {

  readonly defaultBody = {
    code: "",
    libelle: "",
  }
  readonly basePath = 'seuil_rupture'
  constructor(
    private user: UserService,
    private restClient: RestClientService
  ) { }

  get(
    index: number = 0,
    size: number = 10,
    searchTerm: string = '',
    code: 'DIST' | 'PDV' = 'DIST',
    regionId: string = 'all',
  ): Observable<ISeuilResponseBody> {
    let bodyDatas = {
      user: this.user.userMatricule,
      data: { ...this.defaultBody }
    } as {
      index?: number,
      size?: number,
      user: string,
      data: {
        code: string,
        libelle: string,
      } & {[key: string]: string|number}
    }

    bodyDatas.data.code = code;

    bodyDatas = {
      ...bodyDatas,
      index: index,
      size: size
    }
    if (searchTerm && searchTerm.length > 0) bodyDatas.data.libelle = searchTerm
    if (regionId !== 'all') {
      bodyDatas.data = {
        ...bodyDatas.data,
        regionId,
      }
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }

  findOne(id: number): Observable<ISeuilResponseBody> {
    const data = { ...this.defaultBody, id: '' }
    data.id = id.toString()
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": data
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }

  save(payload: SeuilPayload): Observable<any> {
    const requestBody = {
      ...payload,
      regionId: payload.code === 'PDV' ? Number(payload.regionId) : undefined,
      distributeurId: payload.code === 'DIST' ? Number(payload.distributeurId) : undefined,
      valeur: Number(payload.valeur)
    }
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [requestBody]
    }
    return this.restClient.post(`${this.basePath}/create`, bodyDatas)
  }

  update(payload: SeuilPayload): Observable<any> {
    const requestBody = {
      ...payload,
      regionId: payload.code === 'PDV' ? Number(payload.regionId) : undefined,
      distributeurId: payload.code === 'DIST' ? Number(payload.distributeurId) : undefined,
      valeur: Number(payload.valeur)
    }
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [requestBody]
    }
    return this.restClient.post(`${this.basePath}/update`, bodyDatas)
  }

  delete(seuilId: number): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [{id: seuilId}]
    }
    return this.restClient.post(`${this.basePath}/delete`, bodyDatas)
  }
}
