import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ModalImportZoneComponent } from './components/modal-import-zone/modal-import-zone.component';
import { ModalImportTerritoireComponent } from './components/modal-import-territoire/modal-import-territoire.component';
import { ModalImportDistributeurComponent } from './components/modal-import-distributeur/modal-import-distributeur.component';
import { PaginatorComponent } from './components/paginator/paginator/paginator.component';
import { LastUpdateDataComponent } from './components/last-update-data/last-update-data.component';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { NumberHumanFormatPipe } from './pipes/number-human-format.pipe';
import { ModalPdvRuptureComponent } from './components/modal-pdv-rupture/modal-pdv-rupture.component';
import { FiltersModule } from '../views/authenticated/pages/stock-level/filters/filters.module';
import { ModalProfilComponent } from './components/modal-profil/modal-profil.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MainKpiCardListComponent } from './components/main-kpi-card-list/main-kpi-card-list.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ModalSeuilComponent } from './components/modal-seuil/modal-seuil.component';
import { ModalAlerteComponent } from './components/modal-alerte/modal-alerte.component';
import {PageTitleComponent} from "./components/page-title/page-title.component";

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    ModalImportZoneComponent,
    ModalImportTerritoireComponent,
    ModalImportDistributeurComponent,
    PaginatorComponent,
    LastUpdateDataComponent,
    ThousandSeparatorPipe,
    NumberHumanFormatPipe,
    ModalPdvRuptureComponent,
    ModalProfilComponent,
    MainKpiCardListComponent,
    ModalSeuilComponent,
    ModalAlerteComponent
  ],
  imports: [
    CommonModule,
    DataTablesModule,
    NgxUiLoaderModule,
    FiltersModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SweetAlert2Module,
    NgxPermissionsModule.forChild()
  ],
  exports: [
    PaginatorComponent,
    LastUpdateDataComponent,
    ThousandSeparatorPipe,
    NumberHumanFormatPipe,
    MainKpiCardListComponent,
    CommonModule,
    NgxPermissionsModule
  ]
})
export class SharedModule { }
