<div class="modal-header">
    <h4 class="modal-title">Importer un fichier</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <div class="py-4">
        <input type="file" (change)="onFilechange($event)" name="zoneFile">
        <button class="btn btn-primary" type="button" (click)="uploadFile()">Importer le fichier</button>
        <p class="py-3 mt-3 mb-0">
            <a href="javascript: void(0);" class="text-primary" (click)="downloadTemplateFile()">Télécharger la dernière version du fichier</a>
        </p>
        
    </div>
    
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
</div>