import { FiltersService } from './filters.service';
import { Component, OnInit } from '@angular/core';

import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-stock-level-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.scss']
})
export class FiltersComponent implements OnInit {
  thirtyDaysFilter = false
  faSliders = faSliders

  constructor(private filtersService: FiltersService, private userService: UserService,) { }


  ngOnInit(): void {
    this.onChangeThirtyDaysFilter()
  }

  onChangeThirtyDaysFilter(){
    this.filtersService.updateThirtyDaysFilter(this.thirtyDaysFilter)
  }

  onSubmitFilter(){
    this.filtersService.emitFilters()
  }

  showFilterRegionsAndTerritories(){
    return !this.userService.isDistributeur
  }
}
