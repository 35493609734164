import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LastUpdateDataService {
  private lastUpdate = new BehaviorSubject<any>({ startDate: "", endDate: "", startHour: "", endHour: "" });
  lastUpdateData = this.lastUpdate.asObservable();
  constructor() { }

  setLastUpdateData(data: { startDate: string, endDate: string, startHour: string, endHour: string }) {
    this.lastUpdate.next(data);
  }

}
