import { errorHandling } from './../operators/errorHandling.operator';
import { FilterItem, FiltersService, FilterTypes } from '../filters.service';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  inject,
  DestroyRef
} from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  Subscription,
  tap,
  fromEvent,
  filter
} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import dayjs from "dayjs/esm";
import {IDatepickerFilterParam} from "../../../../../../interface/datepicker-filter.interface";
import {LastUpdateDataService} from "../../../../../../services/last-update-data/last-update-data.service";
import {DashboardService} from "../../../../../../services/dashboard/dashboard.service";
import {
  DashboardFilterDataService
} from "../../../../../../services/dashboard-filter-data/dashboard-filter-data.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {UtilitiesService} from "../../../../../../services/utilities/utilities.service";

@Component({
  selector: 'app-multi-selector-filter',
  templateUrl: './multi-selector-filter.component.html',
  styleUrls: ['./multi-selector-filter.component.scss', '../filters.scss']
})
export class MultiSelectorFilterComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef
  @Input() type: Exclude<FilterTypes, 'categorie_DISTRIBUTEUR_param' | 'categorie_PDV_param'>;
  @Input() title: string;
  inputSearchType: 'text' | 'number';
  filterSubject: BehaviorSubject<FilterItem[]>;
  filterSubscription: Subscription
  items: FilterItem[];
  selectedItems: FilterItem[] = [];
  allSelected = false
  dealerCategory: string | null = null
  pdvCategory: string | null = null
  filterCategoryList: Array<{code: string, title: string}> = []
  dealerCategoryList: Array<{code: string, title: string}> = [
    {
      code: 'BANK',
      title: 'Banque',
    },
    {
      code: 'DEALER',
      title: 'Distributeur',
    },
    {
      code: 'DEALER_NONPAY',
      title: 'Dealer agents non payables',
    },
    {
      code: 'OTHER',
      title: 'Autres distributeur',
    },
  ]
  pdvCategoryList: Array<{code: string, title: string}> = [
    {
      code: 'PDV_BANK',
      title: 'PDV rattaché aux banques',
    },
    {
      code: 'PDV_DIST',
      title: 'PDV rattaché aux distributeurs',
    },
    {
      code: 'PDV_DIST_NONPAY',
      title: 'PDV Rattaché aux Dealer agents non payables',
    },
    {
      code: 'AGENT_BANK',
      title: 'Bank Agent',
    },
    {
      code: 'AGENT_RELAI',
      title: 'Agent relai',
    },
    {
      code: 'AGENT_SUPERELAI',
      title: 'Agent Super relai',
    },
    {
      code: 'OTHER',
      title: 'Autre PDV',
    }
  ]
  categoryFilterLabel: string = ''
  datePickerFilterData: IDatepickerFilterParam
  private destroyRef = inject(DestroyRef);

  constructor(
    private filtersService: FiltersService,
    private toastr: ToastrService,
    private dashboardFilterService: DashboardFilterDataService,
    private utilitiesService: UtilitiesService
  ) {
    this.categoryFilterLabel = this.type === 'distributors' ? 'Ou sélectionner une catégorie de distributeur' : 'Ou sélectionner une catégorie de PDV'
    this.filterCategoryList = this.type === 'distributors' ? this.dealerCategoryList : this.pdvCategoryList
  }

  ngOnInit(): void {
    //define input search type
    // this.inputSearchType = this.type === "distributors" ? "number" : "text"
    this.inputSearchType = "text"

    this.filterSubject = this.filtersService.filtersSubjects[this.type]

    this.filterSubscription = this.filterSubject.subscribe({
      next: items => {
        this.items = items
      }
    })

    this.dashboardFilterService.currentFilterState.pipe(
      filter(filterItem => filterItem.startDate !== "" && filterItem.endDate !== "" && filterItem.startHour !== ""),
      tap(filterItem => {
        this.datePickerFilterData = {
          startDate: this.utilitiesService.transformDateIntoEnglishFormat(filterItem.startDate),
          endDate:  this.utilitiesService.transformDateIntoEnglishFormat(filterItem.endDate),
          startHour: filterItem.startHour,
          endHour: filterItem.endHour,
          isLastDate: filterItem.isLastDate
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response) => {

      }
    })
  }

  ngAfterViewInit() {
    fromEvent<any>(this.searchInput.nativeElement, 'keyup').pipe(
      map(($event): string => $event.target.value),
      tap(() => {
        this.items = []
      }),
      filter(term => term.length > 0),
      debounceTime(700),
      distinctUntilChanged(),
      switchMap(term => this.filtersService.searchFilterItems(this.type, term, this.datePickerFilterData).pipe(
        errorHandling((errorMessage)=> this.toastr.error(errorMessage, 'Error'), 'Oops! Une erreur inattendue a été rencontrée lors du chargement des suggestions.', {items:[]})
      )),
    ).subscribe({
      next: this.filtersService.handleItemsApiResponses(this.type)
    })
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe()
  }

  selectItem(item: FilterItem) {
    if (!this.allSelected) {
      let alreadySelected = this.selectedItems.some(myItem => myItem.id === item.id)

      if (!alreadySelected) {
        this.selectedItems.push({ ...item })
        this.updateFilterService()
      }
    }

  }

  removeItem(id: string) {
    if (!this.allSelected) {
      this.selectedItems = this.selectedItems.filter(item => item.id !== id)
      this.updateFilterService()
    }

  }

  toggleAllSelected() {
    this.selectedItems = []

    this.updateFilterService()
  }

  handleCategoryFilter() {
    let filterParam: FilterTypes | null = null
    if(this.type === 'distributors' && this.dealerCategory){
      filterParam = 'categorie_DISTRIBUTEUR_param'
    }
    if(this.type === 'pdvs' && this.pdvCategory){
      filterParam = 'categorie_PDV_param'
    }

    if(!filterParam) {
      this.updateFilterService()
      return;
    }

    const categoryMapping: { [key: string]: string | null } = {
      'categorie_DISTRIBUTEUR_param': this.dealerCategory,
      'categorie_PDV_param': this.pdvCategory
    };
    const selectedCategory = categoryMapping[filterParam];
    if(!selectedCategory || selectedCategory === 'null'){
      this.searchInput.nativeElement.removeAttribute('readonly');
    } else {
      this.searchInput.nativeElement.setAttribute('readonly', 'readonly');
    }
    if (selectedCategory) {
      this.updateFilterService(filterParam, [{ id: filterParam, label: selectedCategory && selectedCategory !== 'null' ? selectedCategory : '' }]);
    }

  }

  updateFilterService(type?: FilterTypes, selectedItems?: FilterItem[] | 'all') {
    const filterType = type ?? this.type
    const items = selectedItems ?? this.selectedItems
    this.filtersService.updateSelectedFilters(filterType, this.allSelected ? 'all' : items)
  }

}
