<div class="app__component main_kpi_card_list_component">
    <div class="indicators-card-listing" ngxUiLoaderBlurred [blur]="10">
        <div class="row">
            <div class="col-md-3 col-sm-6 col-12 item mb-2">
                <div class="card indicator-card yellow">
                    <div class="card-body">
                        <span class="text-muted">Niveau de stock d'UV</span>
                        <h5 class="card-title">Distributeur</h5>
                        <div class="card-text">
                            <div class="indicator-value-wrapper amount">
                                <span class="indicator-value">{{ kpiDatas ? kpiDatas.distNiveauStockUv : 0 }}</span>
                                <span class="indicator-devise text-muted">FCFA TTC</span>
                            </div>
                        </div>
                        <div class="action" (click)="openModal(content)">
                            <img src="assets/images/icons/info-circle.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12 item mb-2">
                <div class="card indicator-card green">
                    <div class="card-body">
                        <span class="text-muted">Niveau de stock d'UV</span>
                        <h5 class="card-title">PDVs</h5>
                        <div class="card-text">
                            <div class="indicator-value-wrapper amount">
                                <span class="indicator-value">{{kpiDatas ? kpiDatas.pdvNiveauStockUv : 0 }}</span>
                                <span class="indicator-devise text-muted">FCFA TTC</span>
                            </div>
                        </div>
                        <div class="action"(click)="openModal(content)">
                            <img src="assets/images/icons/info-circle.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12 item mb-2">
                <div class="card indicator-card red">
                    <div class="card-body">
                        <span class="text-muted">Taux de rupture des PDVs</span>
                        <h5 class="card-title">Courant</h5>
                        <div class="card-text">
                            <div class="indicator-value-wrapper">
                                <span class="indicator-value">{{kpiDatas ? kpiDatas.tauxPdvRupture : '0%'}}</span>
                                <span class="text-muted" *ngIf="kpiDatas">(<span class="text-red">{{ kpiDatas.pdvRupture }}</span>/ {{ kpiDatas.totalPdv }})</span>
                            </div>
                        </div>
                        <div class="action" (click)="openModal(content)">
                            <img src="assets/images/icons/info-circle.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12 item mb-2">
                <div class="card indicator-card orange">
                    <div class="card-body">
                        <span class="text-muted">Taux de rupture des PDVs</span>
                        <h5 class="card-title">Previsionnel</h5>
                        <div class="card-text">
                            <div class="indicator-value-wrapper">
                                <span class="indicator-value">{{ kpiDatas ? kpiDatas.tauxPdvRupturePrevisionnel : '0%'}}</span>
                                <span class="text-muted" *ngIf="kpiDatas">(<span class="text-red">{{ kpiDatas.pdvRupturePrevisionnel }}</span>/ {{ kpiDatas.totalPdvPrevisionnel }})</span>
                            </div>
                        </div>
                        <div class="action" (click)="openModal(content)">
                            <img src="assets/images/icons/info-circle.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ngx-ui-loader [loaderId]="'main-kpi-indicator'" fgsColor="#ff7900" pbColor="#ff7900"></ngx-ui-loader>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Informations</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione eum tempora ad, facilis adipisci eos, eveniet tenetur beatae hic fuga dolorum numquam corrupti consectetur sequi ducimus nesciunt iure? Corrupti, dolores.</p>
        </div>
    </ng-template>
</div>
