<div class="paginator custom__pagination" *ngIf="total > dataPerPage">
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item" [ngClass]="{disabled: currentPage === 0}">
            <a class="page-link" (click)="goToFirstPage()">Début</a>
          </li>
          <li class="page-item" [ngClass]="{disabled: currentPage === 0}">
            <a class="page-link" (click)="goToPreviousPage()">Précédent</a>
          </li>
          <ng-template *ngIf="totalPage <= 50">
            <li class="page-item" [ngClass]="{active: currentPage === i}" *ngFor="let in of counter(totalPage); let i = index"><a class="page-link" (click)="goToPage(i)">{{ i + 1 }}</a></li>
          </ng-template>
          <li class="page-item" [ngClass]="{disabled: currentPage === totalPage - 1}">
            <a class="page-link" (click)="goToNextPage()">Suivant</a>
          </li>
          <li class="page-item" [ngClass]="{disabled: currentPage === totalPage - 1}">
            <a class="page-link" (click)="goToLastPage()">Fin</a>
          </li>
        </ul>
    </nav>
</div>
